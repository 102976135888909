import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import keysIn from 'lodash/keysIn';
import map from 'lodash/map';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import startsWith from 'lodash/startsWith';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import Validator from 'libs/Validator';
import { formatISODate, formatDateAsObject } from 'helpers/datetime';
import { createValidatorRules } from 'helpers/templatedFields';
import { foldObject, unfoldObject } from 'helpers/transformers';
import TemplatedFields from 'components/TemplatedFields';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import Modal from 'components/Modal';
import AutosuggestInput from 'components/Form/AutosuggestInput';
import PhiSetFormPartial from 'components/PhiSetFormPartial';
import CountryIdFormPartial from 'components/CountryIdFormPartial';
import PersonalIdentifierPartial from 'components/PersonalIdentifierPartial';
import DateOfBirthFormPartial from 'components/DateOfBirthFormPartial';
import StaticText from 'components/Form/StaticText';
import countrySettingsShape from 'shapes/countrySettingsShape';
import informationFieldShape from 'shapes/informationFieldShape';
import accessTokenShape from 'shapes/accessTokenShape';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import Statistics from 'modules/Statistics';
import ClinicManagement from 'modules/ClinicManagement';
import Patient from 'modules/Patient';
import Hcp from 'modules/Hcp';
import Visit from 'modules/Visit';
import Tabs from 'components/Tabs';
import * as constants from '../../constants';
import messages from '../../messages';
import validatorRules from './validatorRules.json';
import glucoseLevelTargetsRules from './glucoseLevelTargetsRules.json';


class EditPatientModal extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { openModalId, isStoreInProgress, hasStoreErrors, hasSendStatsErrors } = props;

    if (openModalId !== constants.PROFILE_EDIT_MODAL) {
      return null;
    }

    if (isStoreInProgress !== state.isInProgress && isStoreInProgress) {
      return { isInProgress: true };
    }
    if ((hasStoreErrors || hasSendStatsErrors) && state.isInProgress) {
      return { isInProgress: false };
    }
    return null;
  }


  static propTypes = {
    // Explicit props
    metricConversions: PropTypes.shape({
      weight                   : PropTypes.object,
      height                   : PropTypes.object,
      bloodGlucoseConcentration: PropTypes.object,
    }),
    patient: PropTypes.shape({
      id                : PropTypes.string.isRequired,
      countryId         : PropTypes.number.isRequired,
      phiSetReferenceKey: PropTypes.string,
      storageProvider   : PropTypes.string,
      accessToken       : accessTokenShape,
      firstName         : PropTypes.string,
      lastName          : PropTypes.string,
    }),
    patients: PropTypes.arrayOf(PropTypes.shape({
      id       : PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName : PropTypes.string,
    })),
    phiSet                                     : PropTypes.object, // @TODO: shape
    phiSetDocumentId                           : PropTypes.string,
    isPatientMode                              : PropTypes.bool,
    standards                                  : PropTypes.object,
    // Explicit actions
    onSuccess                                  : PropTypes.func,
    // Implicit props
    isHcpAccount                               : PropTypes.bool,
    passphrase                                 : PropTypes.string,
    activeClinicMembership                     : Account.shapes.clinicMembership,
    activeVisit                                : Visit.shapes.visit,
    isStoreInProgress                          : PropTypes.bool,
    hasStoreErrors                             : PropTypes.bool,
    isSendStatsInProgress                      : PropTypes.bool,
    hasSendStatsErrors                         : PropTypes.bool,
    openModalId                                : PropTypes.string,
    formValues                                 : PropTypes.object,
    clinicPatientTemplate                      : PropTypes.arrayOf(informationFieldShape),
    payers                                     : PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
    countries                                  : PropTypes.arrayOf(App.shapes.country),
    localizationResources                      : PropTypes.object,
    sharingRequest                             : PropTypes.object,
    formValuesTargets                          : PropTypes.object,
    countrySettings                            : countrySettingsShape,
    isFetchPatientCountryLocalizationInProgress: PropTypes.bool,
    hasFetchPatientCountryLocalizationErrors   : PropTypes.bool,
    activeProfileType                          : Account.shapes.profileType,
    formValuesKpi                              : PropTypes.object,
    // Implicit actions
    onFetchPatientCountryLocalization          : PropTypes.func,
    onFetchPayers                              : PropTypes.func,
    onStore                                    : PropTypes.func,
    onSendStats                                : PropTypes.func,
    onSendStatsForClinic                       : PropTypes.func,
    onCloseModal                               : PropTypes.func,
    onFormProcessing                           : PropTypes.func,
    onSetFormValue                             : PropTypes.func,
    onSetFormValues                            : PropTypes.func,
    onUnsetFormValues                          : PropTypes.func,
    onFormErrors                               : PropTypes.func,
    onClearForm                                : PropTypes.func,
    onUpdateProfile                            : PropTypes.func,
    onFetchCustomIdentifiers                   : PropTypes.func,
    onClearFormTargets                         : PropTypes.func,
    onTargetsFormErrors                        : PropTypes.func,
    onSetFormValuesTargets                     : PropTypes.func,
    onSetFormValuesKpi                         : PropTypes.func,
    onClearFormKpi                             : PropTypes.func,
    onSetFormValueKpi                          : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      view        : 'healthInformation', // 'personalInformation' / 'targetsInformation' / 'kpiInformation'
      isInProgress: false,
      errorTabs   : {
        personalInformation: false,
        healthInformation  : false,
        targetsInformation : false,
        kpiInformation     : false,
      },
    };
    this.kpi = {};


    this.validatorRules = { ...validatorRules };
    this.personalIdentifierFields = [];
    this.onDebouncedEnterName = debounce(() => this.onEnterName(), 150);
  }


  componentDidUpdate(prevProps) {
    const {
      patient, phiSet, isPatientMode, openModalId, clinicPatientTemplate,
      isStoreInProgress, hasStoreErrors, isSendStatsInProgress, hasSendStatsErrors,
      standards,
    } = this.props;

    if (openModalId !== constants.PROFILE_EDIT_MODAL) {
      return;
    }

    if (!prevProps.openModalId && openModalId && patient) {
      this.onPopulate(phiSet, patient);
      this.onUpdatePatientValidatorRules();
      this.onPopulatePersonalIdentifier(patient);
    }
    if (prevProps.clinicPatientTemplate !== clinicPatientTemplate) {
      this.onPopulatePersonalIdentifier(patient);
    }


    if (prevProps.isStoreInProgress !== isStoreInProgress && !isStoreInProgress && !hasStoreErrors) {
      const { glucoseLevelTargets } = this;
      const patientStandards = { ...standards, ...glucoseLevelTargets };
      if (isPatientMode) {
        this.props.onSendStats(patient, phiSet, {}, patientStandards, this.props.passphrase);
      } else {
        this.props.onSendStatsForClinic(patient, phiSet, {}, patientStandards, this.props.activeClinicMembership);
      }
    }

    if (prevProps.isSendStatsInProgress !== isSendStatsInProgress && !isSendStatsInProgress && !hasSendStatsErrors) {
      this.onClose();
    }
  }


  onAddValidatorRules(rules) {
    this.validatorRules = { ...this.validatorRules, ...rules };
  }


  onUpdatePatientValidatorRules() {
    const { phiSet } = this.props;
    const weight = get(phiSet, 'summaryData.lastWeight');
    const height = get(phiSet, 'summaryData.lastHeight');
    if (this.showEmail && !this.validatorRules.email) {
      this.validatorRules.email = 'email';
    } else if (!this.showEmail && this.validatorRules.email) {
      delete this.validatorRules.email;
    }

    this.validatorRules.weight = weight ? 'positive, required' : 'positive';
    this.validatorRules.height = height ? 'positive, required' : 'positive';
  }


  onValidateGlucoseLevelTargets() {
    const glucoseLevelValues = { ...get(this.props.formValuesTargets, 'values', {}) };
    return Validator.run(glucoseLevelValues, glucoseLevelTargetsRules, true, true);
  }

  onValidate(rules) {
    const values = { ...get(this.props.formValues, 'values', {}) };
    const { dateOfBirth } = values;
    if (dateOfBirth && dateOfBirth.year && dateOfBirth.month && dateOfBirth.day) {
      values.dateOfBirth = formatISODate(
        new Date(Date.UTC(dateOfBirth.year, dateOfBirth.month - 1, dateOfBirth.day)),
        true,
      );
    } else {
      values.dateOfBirth = '';
    }
    const clonedRules = { ...rules };

    clonedRules.payer = this.isTemplateFieldHidden('payer')
      ? 'trim'
      : createValidatorRules(this.findTemplateFieldByName('payer')).payer;
    return Validator.run(values, clonedRules);
  }


  async onClose() {
    await this.props.onCloseModal();
    const countryId = get(this.props.patient, 'countryId', '');
    const countrySettingsCountryId = get(this.props.countrySettings, 'countryId', '');
    if (countryId && countryId !== countrySettingsCountryId) {
      this.props.onFetchPatientCountryLocalization(countryId);
      this.props.onFetchPayers(countryId);
    }
    this.props.onClearForm();
    this.props.onClearFormTargets();
    this.props.onClearFormKpi();
    this.setState({ view: 'healthInformation', isInProgress: false });
    this.onClearErrorTabs();
  }


  onChangeName(input) {
    this.props.onSetFormValue(input);
    this.onDebouncedEnterName();
  }


  onEnterName() {
    const id = get(this.props.patient, 'id');
    const { firstName, lastName } = get(this.props.formValues, 'values', {});
    const otherPatient = firstName && lastName && find(
      this.props.patients,
      (p) => p.firstName === firstName && p.lastName === lastName && p.id !== id,
    );
    this.requiredRules = {};
    this.validatorRules.dateOfBirth = otherPatient ? 'required' : '*';
  }


  onChangeCountry(input) {
    this.props.onFetchPatientCountryLocalization(input.value);
    this.props.onFetchPayers(input.value);
    this.props.onSetFormValue(input);
  }


  onPopulatePersonalIdentifier(patient) {
    const personalIdentifierTemplate = find(this.props.clinicPatientTemplate, { name: 'personalIdentifier' });
    if (!personalIdentifierTemplate) {
      this.props.onUnsetFormValues(this.personalIdentifierFields);
      this.validatorRules = pick(this.validatorRules,
        keysIn(this.validatorRules).filter((key) => !startsWith(key, 'personalIdentifier')));
      return;
    }
    const personalIdentifierTypeTemplate = find(personalIdentifierTemplate.fields, { name: 'personalIdentifierType' });
    const personalIdentifierTypeOptions = map(get(personalIdentifierTypeTemplate, 'options'), (o) => o.value);
    if (
      !patient.personalIdentifier
        || !includes(personalIdentifierTypeOptions, patient.personalIdentifier.personalIdentifierType)
    ) {
      const values = foldObject({
        personalIdentifierType : personalIdentifierTypeOptions[0],
        personalIdentifierValue: undefined,
      }, 'personalIdentifier');
      this.props.onSetFormValues(values);
      return;
    }
    const personalIdentifierValues = this.getPersonalIdentifierValues(patient);
    this.props.onSetFormValues({ ...personalIdentifierValues });
  }


  onPopulate(phiSet, patient) {
    const conversion = this.props.metricConversions;
    this.props.onClearForm();
    const weight = get(phiSet, 'summaryData.lastWeight') || '';
    const height = get(phiSet, 'summaryData.lastHeight') || '';
    const firstName = get(patient, 'firstName', '');
    const lastName = get(patient, 'lastName', '');
    const payer = get(patient, 'payer', '');
    const gender = get(patient, 'gender');
    const dateOfBirth = get(patient, 'dateOfBirth') || '';
    const countryId = get(patient, 'countryId', '');
    const email = get(patient, 'email', '');
    const phone = get(patient, 'phone', '');
    const otherInformation = get(patient, 'otherInformation', '');
    const personalIdentifier = this.getPersonalIdentifierValues(patient);

    const values = { ...personalIdentifier };
    values.dateOfBirth = formatDateAsObject(dateOfBirth);
    const customPatientIdentifiersValues = get(patient, 'customPatientIdentifiersValues', []);
    forEach(customPatientIdentifiersValues, (customIdentifier) => {
      values[`customIdentifier-${customIdentifier.customIdentifierId}`] = customIdentifier.value;
    });

    this.props.onSetFormValues({
      weight       : weight && this.props.metricConversions.weight.toDisplay(weight),
      height       : height && this.props.metricConversions.height.toDisplay(height),
      diabetesType : get(phiSet, 'diabetesType') || '',
      treatmentType: get(phiSet, 'treatmentType') || '',
      firstName,
      lastName,
      gender,
      countryId,
      payer,
      email,
      phone,
      otherInformation,
      ...values,
    });

    this.onDebouncedEnterName();

    const glucoseConversion = conversion.bloodGlucoseConcentration;
    this.props.onSetFormValuesTargets({
      preMeal_lowThreshold  : glucoseConversion.toDisplay(get(this.props.standards, 'preMeal.lowThreshold')),
      preMeal_highThreshold : glucoseConversion.toDisplay(get(this.props.standards, 'preMeal.highThreshold')),
      postMeal_lowThreshold : glucoseConversion.toDisplay(get(this.props.standards, 'postMeal.lowThreshold')),
      postMeal_highThreshold: glucoseConversion.toDisplay(get(this.props.standards, 'postMeal.highThreshold')),
      critical_lowThreshold : glucoseConversion.toDisplay(get(this.props.standards, 'critical.lowThreshold')),
      critical_highThreshold: glucoseConversion.toDisplay(get(this.props.standards, 'critical.highThreshold')),
    });

    const kpiPhiSet = get(phiSet, 'kpi', {});
    if (kpiPhiSet) {
      Object.keys(kpiPhiSet).forEach((kpiType) => {
        const kpiItem = kpiPhiSet[kpiType];
        if (kpiItem) {
          Object.keys(kpiItem).forEach((item) => {
            let value = kpiItem[item];
            if (!isNull(value)) {
              if (kpiType === 'standardDeviation') {
                value = conversion.bloodGlucoseConcentration.toStorage(value);
              }
              this.kpi = {
                ...this.kpi,
                [`kpi-${kpiType}-${item}`]: value.toString(),
              };
            }
            this.onSetFormValueKpi({ id: `kpi-${kpiType}-${item}`, value });
          });
        }
      });
    }
  }


  onSubmit() {
    this.props.onFormProcessing();
    const { validatedValues, errors } = this.onValidate(this.validatorRules);
    this.props.onFormErrors(errors);
    const { errors: targetsErrors } = this.onValidateGlucoseLevelTargets(this.validatorRules);
    this.props.onTargetsFormErrors(targetsErrors);

    if (!errors && !targetsErrors) {
      this.onClearErrorTabs();
      const {
        metricConversions, patient, phiSet, phiSetDocumentId, onSuccess, activeClinicMembership, activeVisit,
      } = this.props;
      const { weight, height } = validatedValues;
      const measurements = {};
      if (weight) measurements.weight = metricConversions.weight.toStorage(weight);
      if (height) measurements.height = metricConversions.height.toStorage(height);

      const phiEntries = {
        diabetesType : validatedValues.diabetesType,
        treatmentType: validatedValues.treatmentType,
      };

      const validatedValuesProfile = unfoldObject(omit(
        validatedValues,
        ['weight', 'height', 'diabetesType', 'treatmentType'],
      ));

      let glucoseLevelTargets = {};
      if (this.differencesInGlucoseLevelTarget) {
        glucoseLevelTargets = this.glucoseLevelTargets;
      }

      const { kpi } = this.prepareKpiValuesForSubmit();

      const updatedPhiSet = { ...phiSet, ...phiEntries, glucoseLevelTargets, kpi };

      const shouldUpdateMeasurements = this.differencesInHealthData
        || this.differencesInGlucoseLevelTarget
        || this.differencesKpiInformation;

      let isFormHaveChanges = false;
      if (shouldUpdateMeasurements) {
        isFormHaveChanges = true;
        const visitData = pick(activeVisit, ['phisetVisitId']);
        this.props.onStore(measurements, updatedPhiSet, phiSetDocumentId, patient, visitData, onSuccess);
      }

      if (this.differencesInPersonalInformation) {
        isFormHaveChanges = true;
        const patientHealthData = pick(updatedPhiSet, ['diabetesType', 'treatmentType']);
        patientHealthData.weight = get(updatedPhiSet, 'summaryData.lastWeight');
        patientHealthData.height = get(updatedPhiSet, 'summaryData.lastHeight');
        this.props.onUpdateProfile(
          patient, patientHealthData, validatedValuesProfile, activeClinicMembership, activeVisit,
        );
      }

      if (!isFormHaveChanges) {
        this.onClose();
      }
    } else {
      this.onErrorSubmit(errors, targetsErrors);
    }
  }


  onErrorSubmit(errors, targetsErrors) {
    const errorTabs = {
      personalInformation: false,
      healthInformation  : false,
      targetsInformation : false,
      kpiInformation     : false,
    };

    if (!errors) {
      errors = {};
    }

    if (Object.keys(errors).includes('weight', 'height', 'diabetesType', 'treatmentType')) {
      errorTabs.healthInformation = true;
    }
    const personalErrors = omit(errors, ['weight', 'height', 'diabetesType', 'treatmentType']);

    if (!isEmpty(personalErrors)) {
      errorTabs.personalInformation = true;
    }

    if (!isEmpty(targetsErrors)) {
      errorTabs.targetsInformation = true;
    }
    this.setState({ errorTabs });
  }


  onClearErrorTabs() {
    const errorTabs = {
      personalInformation: false,
      healthInformation  : false,
      targetsInformation : false,
    };
    this.setState({ errorTabs });
  }


  onSetFormValueKpi(input) {
    const { id, value } = input;
    if (!value) {
      this.props.onSetFormValue({ id, value });
      return;
    }
    let valueToShow = value.toString();
    const conversion = this.props.metricConversions;
    const { unitSymbol } = conversion.bloodGlucoseConcentration;

    if (id.includes('veryLow') || id.includes('veryHigh')) {
      valueToShow = `> ${valueToShow.replace(/[^\d.-]/g, '')}`;
    }

    if (id.includes('CV')) {
      valueToShow = `${valueToShow.replace(/[^\d.-]/g, '')}%`;
    }

    if (id.includes('standardDeviation')) {
      valueToShow = `${valueToShow.replace(/[^\d.-]/g, '')}${unitSymbol}`;
    }

    if (id.includes('GMI')) {
      if (id.includes('valueHigh')) {
        valueToShow = `> ${valueToShow.replace(/[^\d.-]/g, '')}%`;
      }
      if (id.includes('valueLow')) {
        valueToShow = `< ${valueToShow.replace(/[^\d.-]/g, '')}%`;
      }
    }

    if (!valueToShow.match(/\d+/g)) {
      valueToShow = '';
    }

    if (!isNumber(parseFloat(valueToShow.replace(/[^\d.-]/g, '')))) {
      return;
    }
    this.props.onSetFormValueKpi({ id, value: valueToShow });
  }


  get showEmail() {
    const { sharingRequest } = this.props;
    return !(sharingRequest && sharingRequest.sharingStatus === 'Approved');
  }


  // get isPayerMandatory() {
  //   const { countrySettings } = this.props;
  //   return countrySettings ? countrySettings.isPayerMandatory : false;
  // }


  get isDisabled() {
    const { isFetchPatientCountryLocalizationInProgress, hasFetchPatientCountryLocalizationErrors } = this.props;
    const { errors } = this.onValidate(this.requiredRules);
    return !!errors || isFetchPatientCountryLocalizationInProgress || hasFetchPatientCountryLocalizationErrors;
  }


  get differencesInHealthData() {
    const { phiSet } = this.props;
    if (!phiSet) return false;
    const values = { ...get(this.props.formValues, 'values', {}) };
    const weight = get(phiSet, 'summaryData.lastWeight', '');
    const height = get(phiSet, 'summaryData.lastHeight', '');
    const diabetesType = get(phiSet, 'diabetesType', '');
    const treatmentType = get(phiSet, 'treatmentType', '');

    return values.weight !== this.props.metricConversions.weight.toDisplay(weight)
      || values.height !== this.props.metricConversions.height.toDisplay(height)
      || values.diabetesType !== diabetesType
      || values.treatmentType !== treatmentType;
  }


  get glucoseLevelTargets() {
    const conversion = this.props.metricConversions;
    const formValuesTargets = { ...get(this.props.formValuesTargets, 'values', {}) };
    const glucoseLevelTargets = {};

    Object.keys(formValuesTargets).forEach((id) => {
      const splitId = id.split('_');
      glucoseLevelTargets[splitId[0]] = {
        ...glucoseLevelTargets[splitId[0]],
        [splitId[1]]: conversion.bloodGlucoseConcentration.toStorage(formValuesTargets[id]),
      };
    });

    return glucoseLevelTargets;
  }


  get differencesInGlucoseLevelTarget() {
    const { phiSet, standards } = this.props;
    if (!phiSet) {
      return false;
    }
    const { glucoseLevelTargets } = this;
    const comparableStandards = omit(standards, ['minValue', 'maxValue']);
    return !isEqual(comparableStandards, glucoseLevelTargets);
  }


  get differencesInPersonalInformation() {
    const { patient } = this.props;
    if (!patient) return false;
    const { validatedValues } = this.onValidate(this.validatorRules);
    const validatedValuesProfile = omit(validatedValues, ['weight', 'height', 'diabetesType', 'treatmentType']);
    let hasDifferencesInPersonalInformation = false;
    Object.keys(validatedValuesProfile).forEach((element) => {
      if (
        JSON.stringify(validatedValuesProfile[element]) !== JSON.stringify(patient[element])
        && (!isEmpty(validatedValuesProfile[element]) || patient[element])
      ) {
        hasDifferencesInPersonalInformation = true;
      }
    });

    return hasDifferencesInPersonalInformation;
  }


  get differencesKpiInformation() {
    const formValuesKpi = get(this.props.formValuesKpi, 'values', {});
    const replacedValues = {};
    Object.keys(formValuesKpi).forEach((value) => {
      replacedValues[value] = formValuesKpi[value].replace(/[^\d.-]/g, '');
    });

    return JSON.stringify(replacedValues) !== JSON.stringify(this.kpi);
  }


  isTemplateFieldHidden(fieldName) {
    return !this.findTemplateFieldByName(fieldName);
  }


  findTemplateFieldByName(informationName) {
    return find(this.props.clinicPatientTemplate, (information) => information.name === informationName);
  }


  getPersonalIdentifierValues(patient) {
    const personalIdentifier = get(patient, 'personalIdentifier');
    if (!personalIdentifier) {
      return null;
    }
    const personalIdentifierValues = { ...foldObject(personalIdentifier, 'personalIdentifier') };
    this.personalIdentifierFields = keysIn(personalIdentifierValues);
    return personalIdentifierValues;
  }


  prepareKpiValuesForSubmit() {
    const formValuesKpi = get(this.props.formValuesKpi, 'values', {});

    const kpi = {};

    Object.keys(formValuesKpi).forEach((value) => {
      if (value.includes('kpi')) {
        const objectValue = value.split('-');

        if (objectValue.length > 2) {
          let result = parseFloat(formValuesKpi[value].replace(/[^\d.-]/g, ''));
          if (objectValue[1] === 'standardDeviation') {
            const conversion = this.props.metricConversions;
            result = conversion.bloodGlucoseConcentration.toStorage(result);
          }
          kpi[objectValue[1]] = {
            ...kpi[objectValue[1]],
            [objectValue[2]]: result,
          };
        }
      }
    });

    return {
      kpi,
    };
  }


  renderNav() {
    const { errorTabs } = this.state;
    return (
      <Tabs
        activeItem={this.state.view}
        items={['personalInformation', 'healthInformation', 'targetsInformation', 'kpiInformation']}
        messages={messages.nav}
        action={(view) => this.setState({ view })}
        errors={errorTabs}
        modified={
          {
            personalInformation: this.differencesInPersonalInformation,
            healthInformation  : this.differencesInHealthData,
            targetsInformation : this.differencesInGlucoseLevelTarget,
            kpiInformation     : this.differencesKpiInformation,
          }
        }
      />
    );
  }


  renderActions() {
    return (
      <div className="modal__actions row">
        <div className="col-6">
          <Button
            styleModifier="quaternary"
            labelMessage={messages.buttons.cancel}
            className="btn--block"
            isDisabled={this.state.isInProgress}
            onClick={() => this.onClose()}
          />
        </div>
        <div className="col-6">
          <Button
            type="submit"
            styleModifier="primary"
            labelMessage={messages.buttons.submit}
            className="btn--block btn--filled"
            isDisabled={this.isDisabled}
            isInProgress={this.state.isInProgress}
          />
        </div>
      </div>
    );
  }


  renderTemplateField(fieldNames) {
    const field = this.findTemplateFieldByName(fieldNames);
    if (!field) {
      return null;
    }
    return (
      <TemplatedFields
        template={[field]}
        disabledFields={this.disabledFields || []}
        formValues={this.props.formValues}
        messages={Hcp.messages}
        localizationResources={this.props.localizationResources}
        validatorRules={this.validatorRules}
        onAddValidatorRules={(rules) => this.onAddValidatorRules(rules)}
        onSetFormValue={this.props.onSetFormValue}
        onSetFormValues={this.props.onSetFormValues}
      />
    );

  }


  renderPatientPersonal() {
    return (
      <Form onSubmit={() => this.onSubmit()}>
        <App.components.AlertsBus className="mb-4" />
        <div className="row">
          <FormGroup
            id="firstName"
            labelMessage={messages.labels.firstName}
            className="col-6"
            formValues={this.props.formValues}
          >
            <Input
              placeholder={messages.placeholders.firstName}
              onChange={(input) => this.onChangeName(input)}
            />
          </FormGroup>
          <FormGroup
            id="lastName"
            labelMessage={messages.labels.lastName}
            className="col-6"
            formValues={this.props.formValues}
          >
            <Input
              placeholder={messages.placeholders.lastName}
              onChange={(input) => this.onChangeName(input)}
            />
          </FormGroup>
        </div>
        <FormGroup
          id="email"
          labelMessage={messages.labels.email}
          formValues={this.props.formValues}
        >
          {
            this.showEmail
              ? (
                <Input
                  placeholder={messages.placeholders.email}
                  onChange={this.props.onSetFormValue}
                />
              )
              : <StaticText />
          }
        </FormGroup>
        { this.renderTemplateField('phone') }
        { this.renderTemplateField('otherInformation') }
        { this.renderTemplateField('gender') }
        <CountryIdFormPartial
          countries={this.props.countries}
          formValues={this.props.formValues}
          isDisabled
          onSetFormValue={(input) => this.onChangeCountry(input)}
        />
        <PersonalIdentifierPartial
          informationField={find(this.props.clinicPatientTemplate, { name: 'personalIdentifier' })}
          localizationResources={this.props.localizationResources}
          messages={Hcp.messages}
          formValues={this.props.formValues}
          onAddValidatorRules={(rules) => this.onAddValidatorRules(rules)}
          onSetFormValue={this.props.onSetFormValue}
        />
        <FormGroup
          id="payer"
          labelMessage={Account.messages.labels.payer}
          formValues={this.props.formValues}
        >
          <AutosuggestInput
            suggestions={this.props.payers}
            placeholder={Account.messages.placeholders.payer}
            onChange={this.props.onSetFormValue}
          />
        </FormGroup>
        <DateOfBirthFormPartial
          formValues={this.props.formValues}
          onSetFormValue={this.props.onSetFormValue}
        />
        { this.renderActions() }
      </Form>
    );
  }


  renderPatientHealth() {
    return (
      <Form onSubmit={() => this.onSubmit()}>
        <App.components.AlertsBus className="mb-4" />
        <PhiSetFormPartial
          metricConversions={this.props.metricConversions}
          formValues={this.props.formValues}
          onSetFormValue={this.props.onSetFormValue}
          localizationResources={this.props.localizationResources}
          isActiveProfileTypePwd={Account.constants.PROFILE_TYPES.PWD === this.props.activeProfileType}
          isMale={get(this.props.formValues, 'values.gender') === 'Male'}
        />
        { this.renderActions() }
      </Form>
    );
  }


  renderTargets() {
    const { patient } = this.props;

    return (
      <Form onSubmit={() => this.onSubmit()}>
        <Patient.partials.GlucoseLevelTargets
          activeClinicMembership={this.props.activeClinicMembership}
          phiSet={this.props.phiSet}
          patient={patient}
        />
        { this.renderActions() }
      </Form>
    );
  }


  renderKpi() {
    const { formValuesKpi, patient, phiSet, metricConversions } = this.props;
    const conversion = metricConversions;

    return (
      <Form onSubmit={() => this.onSubmit()}>
        <p>
          <FormattedMessage
            {...messages.infos.editPatientModalKpi}
            values={
              {
                patient: (
                  <b>{ `${patient.firstName} ${patient.lastName}` }</b>
                ),
                diabetesType: (
                  <b><FormattedMessage {...App.messages.diabetesTypes[get(phiSet, 'diabetesType', 'NotSet')]} /></b>
                ),
              }
            }
          />
        </p>
        <ClinicManagement.components.KpiSetting
          type="veryHigh"
          formValues={formValuesKpi}
          onSetValue={(input) => this.onSetFormValueKpi(input)}
          possibleOptions={['allowedMeasurements']}
          conversion={conversion.bloodGlucoseConcentration}
          labelMessage={messages.infos.kpiVeryHigh}
        />
        <ClinicManagement.components.KpiSetting
          type="veryLow"
          formValues={formValuesKpi}
          onSetValue={(input) => this.onSetFormValueKpi(input)}
          possibleOptions={['allowedMeasurements']}
          conversion={conversion.bloodGlucoseConcentration}
          labelMessage={messages.infos.kpiVeryLow}
        />
        <ClinicManagement.components.KpiSetting
          type="standardDeviation"
          formValues={formValuesKpi}
          onSetValue={(input) => this.onSetFormValueKpi(input)}
          possibleOptions={['valueHigh', 'valueLow']}
          conversion={conversion.bloodGlucoseConcentration}
          labelMessage={messages.infos.kpiStandardDeviation}
        />
        <ClinicManagement.components.KpiSetting
          type="CV"
          formValues={formValuesKpi}
          onSetValue={(input) => this.onSetFormValueKpi(input)}
          possibleOptions={['valueHigh']}
          conversion={conversion.bloodGlucoseConcentration}
          labelMessage={messages.infos.kpiCV}
        />
        <ClinicManagement.components.KpiSetting
          type="GMI"
          formValues={formValuesKpi}
          onSetValue={(input) => this.onSetFormValueKpi(input)}
          possibleOptions={['valueHigh', 'valueLow']}
          conversion={conversion.bloodGlucoseConcentration}
          labelMessage={messages.infos.kpiGMI}
        />
        <ClinicManagement.components.KpiSetting
          type="readingsPerDay"
          formValues={formValuesKpi}
          onSetValue={(input) => this.onSetFormValueKpi(input)}
          possibleOptions={['valueLow']}
          conversion={conversion.bloodGlucoseConcentration}
          labelMessage={messages.infos.kpiReadingsPerDay}
        />
        { this.renderActions() }
      </Form>
    );
  }

  renderForm() {
    switch (this.state.view) {
      case 'personalInformation': {
        return this.renderPatientPersonal();
      }
      case 'healthInformation': {
        return this.renderPatientHealth();
      }
      case 'targetsInformation': {
        return this.renderTargets();
      }
      case 'kpiInformation': {
        return this.renderKpi();
      }
      default: {
        return null;
      }
    }
  }


  render() {
    if (!this.props.isHcpAccount) {
      return null;
    }
    return (
      <Modal
        modalId={constants.PROFILE_EDIT_MODAL}
        openModalId={this.props.openModalId}
        styleModifier="xl"
        onClose={() => (this.state.isInProgress ? {} : this.onClose())}
      >
        { this.renderNav() }
        { this.renderForm() }
      </Modal>
    );
  }


}


const mapStateToProps = (state) => ({
  passphrase                                 : Account.selectors.passphrase(state),
  activeClinicMembership                     : Account.selectors.activeClinicMembership(state),
  metricsUnits                               : Account.selectors.metricsUnits(state),
  isHcpAccount                               : Account.selectors.isHcpAccount(state),
  activeProfileType                          : Account.selectors.activeProfileType(state),
  isStoreInProgress                          : CloudDrive.selectors.isStoreMeasurementsInProgress(state) || Hcp.selectors.isStorePatientInfoInProgress(state),
  hasStoreErrors                             : CloudDrive.selectors.hasStoreMeasurementsErrors(state) || Hcp.selectors.hasStorePatientInfoErrors(state),
  isSendStatsInProgress                      : Statistics.selectors.isSendStatsInProgress(state),
  hasSendStatsErrors                         : Statistics.selectors.hasSendStatsErrors(state),
  sharingRequest                             : Hcp.selectors.sharingRequest(state),
  countrySettings                            : Hcp.selectors.countrySettings(state),
  clinicPatientTemplate                      : Hcp.selectors.clinicPatientTemplate(state),
  patients                                   : Hcp.selectors.patients(state),
  payers                                     : Hcp.selectors.payers(state),
  isFetchPatientCountryLocalizationInProgress: Hcp.selectors.isFetchPatientCountryLocalizationInProgress(state),
  hasFetchPatientCountryLocalizationErrors   : Hcp.selectors.hasFetchPatientCountryLocalizationErrors(state),
  activeVisit                                : Visit.selectors.activeVisit(state),
  localizationResources                      : App.selectors.localizationResources(state),
  countries                                  : App.selectors.countries(state),
  openModalId                                : App.selectors.modal(state),
  formValues                                 : App.selectors.formSelector(constants.MEASUREMENTS_FORM)(state),
  formValuesTargets                          : App.selectors.formSelector(Patient.constants.PATIENT_GLUCOSE_SETTINGS_FORM)(state),
  formValuesKpi                              : App.selectors.formSelector(Patient.constants.PATIENT_KPI_SETTINGS_FORM)(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.MEASUREMENTS_FORM;
  const formNameTargets = Patient.constants.PATIENT_GLUCOSE_SETTINGS_FORM;
  const formNameKpi = Patient.constants.PATIENT_KPI_SETTINGS_FORM;

  return {
    onUpdateProfile: (patient, patientHealthData, newPatientValues, clinicMembership) => dispatch(
      Hcp.actions.storePatientInfo({ patient, patientHealthData, newPatientValues, clinicMembership }),
    ),
    onStore: (measurements, phiSet, phiSetDocumentId, patient, visitData, successAction) => dispatch(
      CloudDrive.actions.storeMeasurements(measurements, phiSet, phiSetDocumentId, patient, visitData, successAction),
    ),
    onSendStats: (patientProfile, phiSet, importData, standards, passphrase) => dispatch(
      Statistics.actions.sendStatistics(patientProfile, phiSet, importData, standards, passphrase),
    ),
    onSendStatsForClinic: (patientProfile, phiSet, importData, standards, clinicMembership) => dispatch(
      Statistics.actions.sendStatisticsForClinic(patientProfile, phiSet, importData, standards, clinicMembership),
    ),
    onFetchPatientCountryLocalization: (countryId) => dispatch(Hcp.actions.fetchPatientCountryLocalization(countryId)),
    onFetchPayers                    : (countryId) => dispatch(Hcp.actions.fetchPayers(countryId)),
    onCloseModal                     : () => dispatch(App.actions.closeModal()),
    onFormProcessing                 : () => dispatch(App.actions.startFormProcessing(formName)),
    onSetFormValue                   : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues                  : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onUnsetFormValues                : (ids) => dispatch(App.actions.unsetFormValues(formName, ids)),
    onFormErrors                     : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onClearForm                      : () => dispatch(App.actions.clearForm(formName)),
    onSetFormValuesTargets           : (values) => dispatch(App.actions.setFormValues(formNameTargets, values)),
    onTargetsFormErrors              : (errors) => dispatch(App.actions.setFormErrors(formNameTargets, errors)),
    onClearFormTargets               : () => dispatch(App.actions.clearForm(formNameTargets)),
    onSetFormValuesKpi               : (values) => dispatch(App.actions.setFormValues(formNameKpi, values)),
    onSetFormValueKpi                : (input) => dispatch(App.actions.setFormValue(formNameKpi, input)),
    onClearFormKpi                   : () => dispatch(App.actions.clearForm(formNameKpi)),
  };
};


const ConnectedEditPatientModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditPatientModal);

export default ConnectedEditPatientModal;
