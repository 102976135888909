import get from 'lodash/get';
import validatorRules from './validatorRules.json';


const template = 'range-{type}-{range}-{thresholdLevel}';

function getNameFromTemplate(type, range, thresholdLevel) {
  return template
    .replace('{type}', type)
    .replace('{range}', range)
    .replace('{thresholdLevel}', thresholdLevel);
}


function hasValue(values, valueName) {
  const relatedValue = get(values, valueName);
  return !!relatedValue;
}


function getLowThreasholdValidationRule(values, type, range) {
  const currentThreshold = 'lowThreshold';
  const rules = [];
  if (hasValue(values, getNameFromTemplate(type, range, currentThreshold))) {
    rules.push('positive');
  }
  const currentCriticalThresholdName = getNameFromTemplate(type, 'critical', currentThreshold);
  const relatedCricicalName = hasValue(values, currentCriticalThresholdName)
    ? currentCriticalThresholdName
    : getNameFromTemplate('Default', 'critical', currentThreshold);

  rules.push(`greaterThen ${relatedCricicalName}`);

  return rules.join(', ');
}

function getHighThreasholdValidationRule(values, type, range) {
  const currentThreshold = 'highThreshold';
  const rules = [];
  const currentCriticalThresholdName = getNameFromTemplate(type, 'critical', currentThreshold);
  const relatedCricicalName = hasValue(values, currentCriticalThresholdName)
    ? currentCriticalThresholdName
    : getNameFromTemplate('Default', 'critical', currentThreshold);
  rules.push(`lowerThen ${relatedCricicalName}`);

  if (hasValue(values, getNameFromTemplate(type, range, currentThreshold))) {
    rules.push('positive');
  }

  const currentLowThresholdName = getNameFromTemplate(type, range, 'lowThreshold');
  const relatedLowName = hasValue(values, currentLowThresholdName)
    ? currentLowThresholdName
    : getNameFromTemplate('Default', range, 'lowThreshold');
  rules.push(`greaterThen ${relatedLowName}`);
  return rules.join(', ');
}


export default function createValidationRules(formValues) {

  const thresholdValidatorRules = { ...validatorRules };

  if (formValues) {
    const { values } = formValues;

    Object.keys(values).forEach((value) => {
      if (value.includes('range')) {
        const objectValue = value.split('-');
        const type = objectValue[1];
        if (type === 'Default') return; // For Default validatorRules already defined in validatorRules.json
        const range = objectValue[2];
        const threshold = objectValue[3];
        if (threshold === 'lowThreshold') {
          const rule = getLowThreasholdValidationRule(values, type, range);
          thresholdValidatorRules[value] = rule;
        } else if (threshold === 'highThreshold') {
          const rule = getHighThreasholdValidationRule(values, type, range);
          thresholdValidatorRules[value] = rule;
        }
      }
    });
  }

  return thresholdValidatorRules;
}
