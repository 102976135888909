import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import cn from 'classnames';
import GlucoseLevelsSlider from 'components/Sliders/GlucoseLevelsSlider';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import messages from '../../messages';
import styles from './GlucoseLevelTargets.pcss';


class TargetsPicker extends React.PureComponent {

  static propTypes = {
    // Explicit props
    defaultIsOpen  : PropTypes.bool,
    header         : PropTypes.node,
    valueMin       : PropTypes.number,
    valueMax       : PropTypes.number,
    valueStep      : PropTypes.number,
    valueDragStep  : PropTypes.number,
    formValues     : PropTypes.object,
    conversion     : PropTypes.object,
    locked         : PropTypes.bool,
    targetType     : PropTypes.string,
    countrySettings: PropTypes.object,
    precision      : PropTypes.number,
    phiSet         : PropTypes.object,
    withoutOrder   : PropTypes.bool,
    // Explicit actions
    onRangeChange  : PropTypes.func,
  };

  static defaultProps = {
    valueMin     : 0,
    valueMax     : 300,
    valueStep    : 50,
    valueDragStep: 1,
    locked       : false,
    targetType   : '',
    precision    : 0,
  };


  onOpen(e) {
    e.stopPropagation();
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }));
  }


  render() {
    const { header, targetType, formValues, locked, countrySettings, withoutOrder } = this.props;
    return (
      <div className={
        cn('mb-4', styles.targetsPicker, {
          [styles.withoutBorder]: withoutOrder,
        })
      }
      >
        <h2
          className="brand__paragraph--bold title"
          onClick={(e) => this.onOpen(e)}
          role="presentation"
        >
          { header }
        </h2>
        <div>
          <div>
            <GlucoseLevelsSlider {...this.props} locked={locked} countrySettings={countrySettings} />
          </div>
          <div className="row">
            <FormGroup
              className="col-6 mb-0"
              id={`${targetType}_lowThreshold`}
              labelMessage={messages.labels.glucoseLow}
              formValues={formValues}
              isDisabled={locked}
            >
              <Input
                type="number"
                id={`${targetType}_lowThreshold`}
                placeholder={messages.labels.glucoseLow}
                onChange={(input) => this.props.onRangeChange(input)}
                isDisabled={locked}
              />
            </FormGroup>
            <FormGroup
              className="col-6 mb-0"
              id={`${targetType}_highThreshold`}
              labelMessage={messages.labels.glucoseHigh}
              formValues={formValues}
              isDisabled={locked}
            >
              <Input
                type="number"
                id={`${targetType}_highThreshold`}
                placeholder={messages.labels.glucoseHigh}
                onChange={(input) => this.props.onRangeChange(input)}
                isDisabled={locked}
              />
            </FormGroup>
          </div>
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(TargetsPicker);
